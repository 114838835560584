import { SelectionChangedEvent } from "ag-grid-community";
import { INITIAL_TARIFF_COMPONENT_VALUE_COL_DEF } from "presentation/constant/TariffComponent/TariffComponentDetailMaintenanceColumnDefinition";
import { TariffComponentMaintenanceConstant } from "presentation/constant/TariffComponent/TariffComponentMaintenanceConstant";
import { useTariffComponentMaintenanceVM } from "presentation/hook/TariffComponent/useTariffComponentMaintenanceVM";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useTariffComponentMaintenanceTracked } from "presentation/store/TariffComponent/TariffComponentMaintenanceProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { memo, useCallback, useEffect, useMemo, useRef } from "react";


const TariffComponentDetailTablePanel: React.FC = () => {
    const [tariffComponentState] = useTariffComponentMaintenanceTracked();
    const tariffComponentVM = useTariffComponentMaintenanceVM();
    const [anainfoState] = useANAInfoTracked();
    const { allowCreate } = anainfoState;
    const { masterState, detailState } = tariffComponentState;
    const { isEditable, isAdd } = masterState;
    const { detailList, isAdd: isDetailAdd, isEditable: isDetailEdit, isSliderOpen: isDtlSliderOpen } = detailState;
    const gridRef: any = useRef(null);
    // const rightClickRef: any = createRef();
    // const [anainfoState] = useANAInfoTracked();
    // const {allowUpdate} = anainfoState;
    // const messageBarVM = useMessageBarVM();   

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();

        tariffComponentVM.updateSelectedDetailRows(selectedRows);
    }, [tariffComponentVM])

    const handleDetailAddClick = useCallback(() => {
        tariffComponentVM.onDetailAdd();
    }, [tariffComponentVM])

    /*const handleRowDoubleClick = useCallback((entity: TariffComponentValueEntity) => {
        if (!isEditable && !isAdd && !isDetailAdd && !isDetailEdit && !isDtlSliderOpen) {
            return;
        }
        tariffComponentVM.onDetailRowClick(entity);
    }, [isAdd, isDetailAdd, isDetailEdit, isDtlSliderOpen, isEditable, tariffComponentVM])*/

    const isRowSelectable = useCallback((params: any) => {
        if (isDtlSliderOpen && (isDetailAdd || isDetailEdit)) {
            return false;            
        } else {
            return true;            
        }
    }, [isDetailAdd, isDetailEdit, isDtlSliderOpen]);

    const memoTable = useMemo(() => {
        return (
            <NbisTable
                id='tariff-omponent-detail-table'
                headerLabel={TariffComponentMaintenanceConstant.TARIFF_COMPONENT_VALUE}
                isNewColumnSetting={true}
                columns={INITIAL_TARIFF_COMPONENT_VALUE_COL_DEF}
                data={detailList ?? []}
                showPaginator={false}
                editable={false}
                showAddIcon={allowCreate && (isEditable || isAdd || isDetailAdd || isDetailEdit)}
                showUploadIcon={allowCreate && (isEditable || isAdd || isDetailAdd || isDetailEdit)}
                onAddClick={handleDetailAddClick}
                showDeleteButton={false}
                showReloadIcon={false}
                isScrollHighlighted={true}
                selectionMode={true}
                isRowHighligted={true}
                handleSelectionChanged={handleSelectionChange}
                //onRowDoubleClick={(e: any, entity: TariffComponentValueEntity) => handleRowDoubleClick(entity)}
                gridHeight="customHeight"
                customHeight="calc(100vh - 75px)"
                ref={gridRef}                
                handleRowSelectable={isRowSelectable}
            />
        );
    }, [detailList, allowCreate, isEditable, isAdd, isDetailAdd, isDetailEdit, handleDetailAddClick, handleSelectionChange, isRowSelectable])

    useEffect(() => {
        if (gridRef.current && gridRef.current.gridRef && gridRef.current.gridRef.current && gridRef.current.gridRef.current.api) {
            gridRef.current.gridRef.current.api.redrawRows();
        }
    }, [isAdd, isEditable, isDtlSliderOpen])
    
    return <><TableWrapper>
        {memoTable}
    </TableWrapper>
    </>;
}

export default memo(TariffComponentDetailTablePanel);
