import _ from "lodash";
import { TariffComponentMaintenanceConstant } from "presentation/constant/TariffComponent/TariffComponentMaintenanceConstant";
import { useTariffComponentMaintenanceVM } from "presentation/hook/TariffComponent/useTariffComponentMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useTariffComponentMaintenanceTracked } from "presentation/store/TariffComponent/TariffComponentMaintenanceProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import { useCallback, useMemo, useState } from "react";
import { FieldType, HPHButton, HPHCheckbox, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarCaption, Sidebarheader, SidebarTitle } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const TariffComponentDetailFormPanel = () => {
    const [tariffComponentState] = useTariffComponentMaintenanceTracked();
    const tariffComponentVM = useTariffComponentMaintenanceVM();
    const messageBarVM = useMessageBarVM();
    const SCREEN_CONSTANT = TariffComponentMaintenanceConstant;
    const { detailState } = tariffComponentState;
    const { currentSelectItem, editingEntity, detailList, isAdd, isEditable, isRead, isSaveClicked, allFormState } = detailState;

    const currentEntity = isRead ? currentSelectItem : editingEntity;
    const [isLoading, setIsLoading] = useState(false);

    const handleCancelClick = () => {
        tariffComponentVM.onDetailCancel();
    }

    const handleSaveClick = useCallback(async () => {
        if (isAdd && !_.isEmpty(detailList) && detailList.find(e => e.tariffCompValueCode === currentEntity.tariffCompValueCode)) {
            messageBarVM.showError(`Tariff Component Value "${currentEntity.tariffCompValueCode}" already exists.`);
            return;
        }

        setIsLoading(true);
        tariffComponentVM.onDetailSaveClicked();
        const res = await tariffComponentVM.onTempSaveDetail(currentEntity);
        setIsLoading(false);
        if (res) {
            messageBarVM.showError(res["mandatoryCheckFail"]);
        }
    }, [currentEntity, detailList, isAdd, messageBarVM, tariffComponentVM])

    const memoTarCompValCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={!isAdd}
                isShowOptional={false}
                readOnlyValue={currentEntity?.tariffCompValueCode || ''}
                fieldValue={currentEntity?.tariffCompValueCode}
                fieldLabel={SCREEN_CONSTANT.TARIFF_COMPONENT_VALUE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffCompValueCode'}
                isShowMissingError={true}
                maxLength={9}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffComponentVM.onDetailFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isAdd, currentEntity?.tariffCompValueCode, SCREEN_CONSTANT.TARIFF_COMPONENT_VALUE, isSaveClicked, tariffComponentVM])

    const memoDesc = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "330px", marginBottom: "24px" }}>

            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentEntity?.tariffCompValueDesc || ''}
                fieldValue={currentEntity?.tariffCompValueDesc}
                fieldLabel={SCREEN_CONSTANT.TARIFF_COMPONENT_VALUE_DESC}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tariffCompValueDesc'}
                isShowMissingError={true}
                maxLength={35}
                requiredFieldList={[]}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    tariffComponentVM.onDetailFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [allFormState, isRead, currentEntity?.tariffCompValueDesc, SCREEN_CONSTANT.TARIFF_COMPONENT_VALUE_DESC, isSaveClicked, tariffComponentVM])

    const memoActiveInd = useMemo(() =>
        <>
            <div className='im-flex-row-item' style={{ width: "150px", marginBottom: "24px" }}>

                <NbisCommonField
                    fieldKey={"activeInd"}
                    requiredFieldList={[]}
                    fieldLabel={SCREEN_CONSTANT.ACTIVE_IND}
                    fieldType={FieldType.TEXT}
                    isReadOnly={true}
                    onFieldChange={() => { }} />
                <HPHCheckbox
                    label={""}
                    checked={currentEntity?.activeInd === 'Y'}
                    disabled={isRead}
                    onChange={(e) => tariffComponentVM.onDetailCheckboxChange(e.checked, "activeInd")}
                />
            </div>
        </>
        , [SCREEN_CONSTANT.ACTIVE_IND, currentEntity?.activeInd, isRead, tariffComponentVM])

    return (
        <div className='side-form-content-wrapper' style={{ position: 'relative', height: "100vh-128px" }}>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>
                            <SidebarTitle>{isAdd ? 'NEW VALUE' : currentEntity.tariffCompValueCode?? ''}</SidebarTitle>
                            {(isAdd || (isEditable && !isRead)) && <SidebarCaption>"All fields are required unless indicated as 'optional'"</SidebarCaption>}
                        </SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" />}

            <div className={'add-edit-form'} style={{ height: '50vh', maxHeight: '50vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoTarCompValCode}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoDesc}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoActiveInd}
                        </CriteriaItemContainer>
                    </div>
                </div>
                <div className='im-charge-data-search-criteria-buttons' >
                    {
                        <>
                            <HPHButton label={isRead ? 'Close' : 'Cancel'} size={'Small'} theme={'Secondary'} onClick={handleCancelClick} />
                            {!isRead && <HPHButton label={isAdd ? 'Add' : "Update"} size={'Small'} theme={'Primary'} onClick={() => handleSaveClick()} />}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}
